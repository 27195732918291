import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import Policy from "./layout/policy";
import Home from "./layout/home";
import "bootstrap/dist/css/bootstrap.rtl.min.css"
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path={"*"} index element={<Home />} />
        <Route index path={"/cookie-policy"} element={<Policy />} />
      </Routes>
    </div>
  );
}

export default App;
